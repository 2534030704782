import axios from "axios";
import socketMixin from "@/socket-mixin.js";
import socket from "@/socket.js";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import de from "@fullcalendar/core/locales/de";
import it from "@fullcalendar/core/locales/it";
import { EventBus } from "@/event-bus";
import { German } from "flatpickr/dist/l10n/de.js";
import { Italian } from "flatpickr/dist/l10n/it.js";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import "flatpickr/dist/plugins/confirmDate/confirmDate.css";
var VueScrollTo = require("vue-scrollto");
import permissionMixin from "@/permissions-mixin.js";

export default {
  mixins: [socketMixin, permissionMixin],

  components: {
    "truck-job-modal": () => import("@/components/modals/truck-job-modal.vue"),
    truck: () => import("@/components/steps/planning/truck.vue"),
    "materials-footer": () =>
      import("@/components/steps/planning/materials-footer.vue"),
    "material-data": () =>
      import("@/components/steps/planning/material-data.vue"),
    "material-weight": () =>
      import("@/components/steps/planning/material-weight.vue"),
    "final-destination": () =>
      import("@/components/steps/planning/final_destination.vue"),
    "stop-destination": () => import("@/components/steps/planning/stop.vue"),
    "job-dates-modal": () => import("@/components/modals/job_dates_modal.vue"),
    "checkin-modal": () => import("@/components/modals/checkin-modal.vue"),
    "truck-job-comments-modal": () =>
      import("@/components/modals/truck-job-comments-modal.vue"),
    "kanban-board": () =>
      import("@/components/steps/planning/kanban-board.vue"),
    "stop-modal": () => import("@/components/modals/stop-modal.vue"),
    FullCalendar,
  },

  created() {
    EventBus.$on("set_current_material_drag", (data) => {
      this.currentMaterialDrag = data;
      if (!this.currentMaterialDrag.QtyWas)
        this.currentMaterialDrag.QtyWas = this.currentMaterialDrag.Qty;
    });
  },

  data() {
    return {
      currentMaterialDrag: {},
      currentTruckJobMaterialDrag: {},
      currentJobDrag: {},
      showModal: false,
      modal_amount_choosed: 0,
      temp: {},
      show_job_dates_modal: false,
      selected_job: {},
      show_checkin_modal: false,
      show_tj_comments_modal: false,
      showTruckJobsList: false,
      show_stop_modal: false,
      is_created: false,
      stop_modal_data: {},
      stop_modal_truck_job: {},
      stop_modal_job: {},
      selected_gate_index: "",

      flatpicker_config: {
        enableTime: true,
        altFormat: "Y-m-d H:i",
        dateFormat: "Y-m-d H:i",
        altInput: true,
        altInputClass: "border_col input is-small is-info",
        wrap: true,
        allowInput: true,
        locale:
          this.$i18n.locale == "de"
            ? German
            : this.$i18n.locale == "en"
            ? "en"
            : Italian,

        plugins: [
          new ConfirmDatePlugin({
            confirmIcon: "<i class='fa fa-check'></i>", // your icon's html, if you wish to override
            confirmText: "",
            showAlways: true,
            theme: "info",
          }),
        ],
        // disable:[{'from': new Date(this.$parent.selected_job.DayTimeOut), 'to': new Date(8640000000000000)}]
        // disable:[{'from': new Date(this.$parent.selected_job.DayTimeOut), 'to': new Date(8640000000000000)}]
      },
    };
  },
  computed: {
    truck_info() {
      console.log(this.$parent.selected_warehouse_jobs);
      var truckInfo = {
        nr_trucks: 0,
        planned: 0,
        to_plan: 0,
        loaded: 0,
      };
      this.$parent.selected_warehouse_jobs.map((j, index) => {
        if (this.$parent.direction) {
          truckInfo.nr_trucks += j.truck_jobs.length;
          j.truck_jobs.map((tj) => {
            if (tj.direction == (this.$parent.direction ? 0 : 1)) {
              if (tj.status_id == 0) truckInfo.to_plan += 1;
              else if (tj.status_id == 1) truckInfo.planned += 1;
              else if (tj.status_id == 2) truckInfo.loaded += 1;
            }
          });
        } else {
          if (index == 0) {
            truckInfo.nr_trucks += j.returning_jobs.length;
            j.returning_jobs.map((tj) => {
              if (tj.direction == (this.$parent.direction ? 0 : 1)) {
                if (tj.status_id == 0) truckInfo.to_plan += 1;
                else if (tj.status_id == 1) truckInfo.planned += 1;
                else if (tj.status_id == 2) truckInfo.loaded += 1;
              }
            });
          }
        }
      });
      return truckInfo;
    },
    calendarOptions: {
      get: function () {
        return {
          plugins: [dayGridPlugin, interactionPlugin],
          initialView: "dayGridMonth",
          eventClick: this.setWarehouseDate,
          locales: [de, it],
          locale: this.$i18n.locale,
          // dateClick: this.set_warehouse_dates,
          events: this.warehouse_dates,
          eventContent: function (eventInfo) {
            return { html: eventInfo.event.extendedProps.customHtml };
          },
          height: 800,
        };
      },
      set: function (v) {
        console.log("calendar setter", v);
      },
    },
    warehouse_dates() {
      var a = [
        ...this.$parent.selected_warehouse_jobs_materials.map((e) => {
          return {
            id: e.ID,
            title: e.Number,
            backgroundColor:
              "#" + Math.floor(Math.random() * 16777215).toString(16),
            customHtml:
              '<div class="has-text-white"><i class="fas fa-hashtag mr-1"></i>' +
              e.Number +
              " | " +
              e.ID +
              ' &emsp; <i class="fas fa-user-check"></i> ' +
              e.Arranger.DisplayName +
              ' &emsp; <i class="fas fa-calendar-check"></i> ' +
              this.$moment(e.DayTimeOut).format("D/M/YYYY") +
              " - " +
              this.$moment(e.DayTimeIn).format("D/M/YYYY") +
              ' &emsp; <i class="fas fa-boxes mr-1"></i> ' +
              this.material_group +
              " </div>",
            start: this.$parent.direction
              ? "departure_date" in e
                ? e.departure_date
                : e.DayTimeOut
              : e.DayTimeIn,
            end:
              "pickup_date" in e
                ? this.return_dates(e.pickup_date)
                : this.return_dates(e.DayTimeIn),
            textColor: "red",
          };
        }),
        ...this.$parent.selected_warehouse_jobs_materials.map((e) => {
          return {
            id: e.ID * 100,
            start: String(e.DayTimeOut.split(" ")[0]).split("T")[0],
            end: String(this.return_dates(e.DayTimeIn).split(" ")[0]).split(
              "T"
            )[0],
            display: "background",
            overlap: false,
          };
        }),
      ];
      return a;
    },
    material_group() {
      var group = [];
      var all_groups = "";
      this.$parent.selected_warehouse_jobs_materials.map((e) => {
        e.materials.map((material) => {
          group.push(material.Group);
        });
      });
      for (let index = 0; index < group.length; index++) {
        if (group[index] != group[index + 1]) {
          all_groups = group[index];
        }
      }
      return all_groups;
    },
  },
  mounted() {
    socket.on("someone said", this.socket_listener);
  },

  beforeDestroy() {
    socket.off("someone said", this.socket_listener);
  },

  methods: {
    socket_listener(data) {
      console.log("socket of planning", data);
      let operator =
        '<p class = "has-text-primary-dark">' + " " + data.by.name + "</p>";
      if (data.type == "new truck") {
        this.$parent.project_trucks_jobs.push(data.data);
        if (this.$parent.selected_warehouse_key)
          this.$parent.select_warehouse(this.$parent.selected_warehouse_key);
        this.$toast(
          "<div>" + "A truck has been added by" + operator + "</div>",
          "info"
        );
      }
      if (data.type == "new parameter") {
        this.$parent.warehouses_parameters.push({
          ...data.data,
          truck_id: data.id,
          name: data.value,
          data: data.new_data,
        });
      } else if (
        data.type == "edit truck header" ||
        data.type == "job status changed"
      ) {
        console.log("update", data);
        //update the main array of truck jobs
        var was_fount = false;
        for (let i = 0; i < this.$parent.project_trucks_jobs.length; i++) {
          if (
            (data.type == "edit truck header"
              ? data.data.project_job_id
              : data.data.guid) ==
            this.$parent.project_trucks_jobs[i].details.guid
          ) {
            if (data.type == "edit truck header") {
              this.$parent.project_trucks_jobs[i].details = { ...data.data };
              was_fount = true;
            } else if (data.type == "job status changed")
              this.$parent.project_trucks_jobs[i].status_id =
                data.data.status_id;
          }
        }
        if (was_fount == false && data.type == "edit truck header") {
          // should be a stop then
          for (let i = 0; i < this.$parent.project_trucks_jobs.length; i++) {
            for (
              let j = 0;
              j < this.$parent.project_trucks_jobs[i].stops.length;
              j++
            ) {
              if (
                data.data.project_job_id ==
                this.$parent.project_trucks_jobs[i].stops[j].guid
              ) {
                this.$parent.project_trucks_jobs[i].stops[j].details = {
                  ...data.data,
                };
                was_fount = true;
              }
            }
          }
          if (was_fount) this.$parent.revaluateSelectedWarehouseJobs();
          // this.$toast('<div>' + 'A gate has been edited' + ' Operator:  ' + operator + '</div>', 'info')
        }
        for (let j = 0; j < this.$parent.selected_warehouse_jobs.length; j++) {
          //update the array of truck job
          for (
            let k = 0;
            k < this.$parent.selected_warehouse_jobs[j].truck_jobs.length;
            k++
          ) {
            if (
              (data.type == "edit truck header"
                ? data.data.project_job_id
                : data.data.guid) ==
              this.$parent.selected_warehouse_jobs[j].truck_jobs[k].details
                .project_job_id
            ) {
              if (data.type == "edit truck header")
                this.$parent.selected_warehouse_jobs[j].truck_jobs[k].details =
                  { ...data.data };
              else if (data.type == "job status changed")
                this.$parent.selected_warehouse_jobs[j].truck_jobs[
                  k
                ].status_id = data.data.status_id;
            }
          }

          //update the array of returning job
          for (
            let k = 0;
            k < this.$parent.selected_warehouse_jobs[j].returning_jobs.length;
            k++
          ) {
            if (
              (data.type == "edit truck header"
                ? data.data.project_job_id
                : data.data.guid) ==
              this.$parent.selected_warehouse_jobs[j].returning_jobs[k].details
                .project_job_id
            ) {
              if (data.type == "edit truck header")
                this.$parent.selected_warehouse_jobs[j].returning_jobs[
                  k
                ].details = { ...data.data };
              else if (data.type == "job status changed")
                this.$parent.selected_warehouse_jobs[j].returning_jobs[
                  k
                ].status_id = data.data.status_id;
            }
          }
        }

        if (data.type == "edit truck header")
          this.$toast(
            "<div>" + "A truck has been edited by " + operator + "</div>",
            "info"
          );
        else if (data.type == "truck departed")
          this.$toast(
            "<div>" +
              "A truck has been set as Departed by " +
              operator +
              "</div>",
            "info"
          );
        else if (data.type == "job status changed") {
          if (data.data.status_id == 1) {
            this.$toast(
              "<div>" +
                this.$t("job_planning_completed") +
                " Operator:  " +
                operator +
                "</div>",
              "info"
            );
          }
          if (data.data.status_id == 2) {
            if (!data.data.details.departed_at) {
              this.$toast(
                "<div>" +
                  this.$t("job_loading_completed") +
                  " Operator:  " +
                  operator +
                  "</div>",
                "info"
              );
            } else {
              this.$toast(
                "<div>" +
                  this.$t("truck_departed") +
                  " Operator:  " +
                  operator +
                  "</div>",
                "info"
              );
            }
          }
        }
      } else if (data.type == "add material") {
        this.$parent.project_trucks_jobs.forEach((p) => {
          if (p.guid == data.data.project_job_id) p.Qty.push({ ...data.data });
        });
        this.$parent.revaluateSelectedWarehouseJobs();
        this.$toast(
          "<div>" + "A new material has been added " + operator + "</div>",
          "info"
        );
      } else if (data.type == "edit material") {
        for (let i = 0; i < this.$parent.project_trucks_jobs.length; i++) {
          if (
            data.data.project_job_id == this.$parent.project_trucks_jobs[i].guid
          ) {
            for (
              let j = 0;
              j < this.$parent.project_trucks_jobs[i].Qty.length;
              j++
            ) {
              if (
                data.data.guid ==
                this.$parent.project_trucks_jobs[i].Qty[j].guid
              )
                this.$parent.project_trucks_jobs[i].Qty[j].loaded_amount =
                  data.data.loaded_amount;
            }
          }
        }
        this.$parent.revaluateSelectedWarehouseJobs();
        this.$toast(
          "<div>" + "A new material has been edited " + operator + "</div>",
          "info"
        );
      } else if (data.type == "delete truck material") {
        for (let i = 0; i < this.$parent.project_trucks_jobs.length; i++) {
          if (data.data[0].guid == this.$parent.project_trucks_jobs[i].guid) {
            for (
              let j = 0;
              j < this.$parent.project_trucks_jobs[i].Qty.length;
              j++
            ) {
              if (
                data.data[1].guid ==
                this.$parent.project_trucks_jobs[i].Qty[j].guid
              ) {
                this.$parent.project_trucks_jobs[i].Qty = [
                  ...this.$parent.project_trucks_jobs[i].Qty.filter(
                    (a) => a.guid != data.data[1].guid
                  ),
                ];
              }
            }
          }
        }
        this.$parent.revaluateSelectedWarehouseJobs();
        this.$toast(
          "<div>" + "A material has been deleted " + operator + "</div>",
          "info"
        );
      } else if (data.type == "delete truck job") {
        console.log("delete before", data.data, this.$parent.$parent.jobs);
        this.$parent.selected_warehouse_jobs = [
          ...this.$parent.selected_warehouse_jobs.map((swj) => {
            if (this.$parent.direction)
              swj.truck_jobs = swj.truck_jobs.filter(
                (t) => t.guid != data.data.guid
              );
            else
              swj.returning_jobs = swj.returning_jobs.filter(
                (t) => t.guid != data.data.guid
              );
            return swj;
          }),
        ];
        this.$parent.$parent.jobs = [
          ...this.$parent.$parent.jobs.map((atj) => {
            if (atj.truck_jobs) {
              if (this.$parent.direction)
                atj.truck_jobs = atj.truck_jobs.filter(
                  (tr) => tr.guid != data.data.guid
                );
              else
                atj.returning_jobs = atj.returning_jobs.filter(
                  (tr) => tr.guid != data.data.guid
                );
            }
            return atj;
          }),
        ];
        // for (let i = 0; i < this.$parent.project_trucks_jobs.length; i++){
        // 	if (data.data.guid == this.$parent.project_trucks_jobs[i].guid)
        // 		this.$parent.project_trucks_jobs = [...this.$parent.project_trucks_jobs.filter(t => t.guid != data.data.guid)]
        // }
        console.log("delete socket", this.$parent.project_trucks_jobs);
        this.$parent.invalidateSelectedWarehouseJobs();

        this.$toast(
          "<div>" + "A truck has been deleted " + operator + "</div>",
          "info"
        );
      } else if (data.type == "add note") {
        this.$toast(
          "<div>" +
            "A new material note has been added by" +
            operator +
            " " +
            "</div>",
          "info"
        );
      } else if (data.type == "delete note") {
        this.$toast(
          "<div>" +
            "A material note has been deleted by" +
            operator +
            " " +
            "</div>",
          "info"
        );
      } else if (data.type == "edit note") {
        this.$toast(
          "<div>" +
            "A material note has been edited by" +
            operator +
            " " +
            "</div>",
          "info"
        );
      } else if (data.type == "new stop") {
        this.$parent.project_trucks_jobs.map((tj) => {
          if (data.data.parent_job == tj.guid) tj.stops.push(data.data);
        });
        this.$parent.revaluateSelectedWarehouseJobs();
        this.$toast(
          "<div>" + "A stop has been added " + operator + "</div>",
          "info"
        );
        // console.log('socket', data.data, this.$parent.project_trucks_jobs)
      } else if (data.type == "delete stop job") {
        this.$parent.project_trucks_jobs = this.$parent.project_trucks_jobs.map(
          (tj) => {
            if (data.data.parent_job == tj.guid) {
              tj.stops = [...tj.stops.filter((s) => s.guid != data.data.guid)];
            }
            return tj;
          }
        );
        this.$parent.revaluateSelectedWarehouseJobs();

        this.$toast(
          "<div>" + "A stop has been deleted " + operator + "</div>",
          "info"
        );
        // console.log('socket', data.data, this.$parent.project_trucks_jobs)
      }
    },
    getLinkedMaterialNotes(m) {
      let a = 0;
      this.$parent.$parent.jobs.map((j) => {
        j.materials.map((mat) => {
          // if(mat.date_amount)
          mat.date_amount.map((md) => {
            if (md.m_linked == m.IdST && md.warehouse_job == m.job_id) {
              a = parseInt(md.planning_notes ? md.planning_notes : 0);
              let b = parseInt(md.warehouse_notes ? md.warehouse_notes : 0);
              a = a + b;
              console.log(
                m,
                md,
                md.m_linked + " == " + m.IdST,
                md.warehouse_job,
                m.job_id
              );
              // console.log('a')
              // console.log(md.m_guid, m.IdST, md, m.job_id)
            }
          });
          //console.log(m)
          // if(m.IdST == mat)
        });
      });

      if (a > 0) return a;
    },
    return_dates(date) {
      let a = this.$moment(date).add(1, "days").format();
      return a;
    },
    toggleShowTruckList(job) {
      //job.showTruckJobsList = !job.showTruckJobsList
      this.$parent.selected_warehouse_jobs = [
        ...this.$parent.selected_warehouse_jobs.map((o) => {
          if (o.ID == job.ID) {
            o.showTruckJobsList = !o.showTruckJobsList;
          }
          return o;
        }),
      ];
    },
    openWarehouseDate(event) {
      //console.log('open date ', event)
      this.$parent.open_collapse_job("collapsed", { ID: event.ID });
      this.$parent.current_warehouse_date = event.ID;
    },
    setWarehouseDate(event) {
      this.$parent.open_collapse_job("collapsed", { ID: event.event.id });
      // this.$parent.current_warehouse_date = event.event.id
      setTimeout(() => {
        VueScrollTo.scrollTo("#wj" + event.event.id, 1000, {});
      }, 300);
      this.$parent.current_warehouse_date = -1;
    },
    getExport(data_type, job, type = "xls", operation = "operational") {
      let tmp =
        data_type == "warehouse" ? { warehouse_job: job } : { truck_job: job };
      tmp =
        operation == "operational"
          ? { ...tmp, operation: "operational" }
          : { ...tmp, operation: "executive" };
      // .filter(p => p.name == "Supervisor")
      // job.Stock.ID
      let warehouse_supervisors = [];
      this.$parent.all_project_parameters.map((p) => {
        if (p.name == "Supervisor" && job.Stock.ID == p.related_id) {
          warehouse_supervisors.push(p);
        }
      });

      axios({
        url: this.$tetris_server + "/" + type + "/export", //your url
        method: "POST",
        responseType: "blob", // important
        // data: tmp
        data: {
          tmp,
          event_details: this.$parent.$parent.event_details,
          truck_prefix: this.$parent.$parent.truck_prefix,
          warehouse_supervisors: warehouse_supervisors,
          parameters: this.$parent.all_project_parameters,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        if (type == "pdf") {
          let file =
            operation == "operational"
              ? "Operational Pdf.pdf"
              : "Execitove Pdf.pdf";
          link.setAttribute("download", file);
        } else link.setAttribute("download", "file." + type); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    isMaterialDraggable(material, job) {
      if (job.status_id != 3 && this.$parent.direction)
        return (
          (material.Qty > 0 ||
            material.Remains != 0 ||
            (material.Remains > 0 && job.status_id == 1)) &&
          job.truck_jobs &&
          job.truck_jobs.length > 0
        );
      else {
        // console.log('material', material)
        return material.LoadedAmount > parseInt(material.ReturnedAmount);
      }
    },
    getParameterVisibleFields(id, list, token, short = false) {
      let a = list.filter((l) => l.id == id);
      if (a.length == 1) {
        let fields = JSON.parse(a[0].form_value);
        let truck_name = "";
        fields.map((f) => {
          if (f.value && f.preview && !short) truck_name += f.value + token;
          else if (f.value && f.preview && short && truck_name == "")
            truck_name = f.value;
        });
        truck_name = truck_name ? truck_name : "";

        if (short) {
          return a[0].data ? a[0].data.name : truck_name;
        }
        return truck_name + (a[0].data ? " " + a[0].data.name : "");
      } else return id;
    },
    getTrucksName(id, list) {
      let a = list.filter((l) => l.id == id);
      if (a.length == 1) {
        let fields = JSON.parse(a[0].form_value);
        let truck_name = fields[0].value;
        truck_name = truck_name ? truck_name : "";
        return truck_name + (a[0].data ? " " + a[0].data.name : "");
      } else return id;
    },
    getTruckName(id, list) {
      let a = list.filter((l) => l.id == id);
      if (a.length == 1) {
        let fields = JSON.parse(a[0].form_value);
        let truck_name = fields[0].value;
        return (
          "<b>" +
          truck_name +
          "</b><br><small>" +
          (a[0].data ? a[0].data.name : "") +
          "</small>"
        );
      } else return id;
    },
    getTruckData(id, list) {
      let a = list.filter((l) => l.id == id);
      if (a.length == 1) {
        let fields = JSON.parse(a[0].form_value);
        return { data: a[0].data, fields };
      } else return {};
    },
    dragForStopJobMaterial(evt, m, truck) {
      // debugger; // eslint-disable-line no-debugger
      this.currentMaterialDrag = m;
      console.log("dragForStopJobMaterial", truck);
      // EventBus.$emit("set_current_material_drag", m);

      this.currentTruckJobMaterialDrag = m;
      // this.$parent.revaluateSelectedWarehouseJobs();
    },
    startDrag(evt, m) {
      this.currentMaterialDrag = m;
      if (!this.currentMaterialDrag.QtyWas)
        this.currentMaterialDrag.QtyWas = this.currentMaterialDrag.Qty;
    },
    endDrag(evt, j) {
      console.log(j, this.currentMaterialDrag);

      var should_continue = true;

      //blocking material drag to the connected truck job on return
      if (!this.$parent.direction && j.connected_project_id) {
        should_continue = false;
        this.$toast(
          "<div>" + "You can not plan this material" + " " + "</div>",
          "danger"
        );
      }

      if (
        j.ej_id != this.currentMaterialDrag.job_id &&
        this.$parent.direction &&
        !j.connected_project_id
      ) {
        should_continue = false;
        if (
          confirm(
            "You are moving the material amount from the job " +
              this.currentMaterialDrag.job_id +
              " to " +
              j.ej_id
          )
        ) {
          console.log(this.currentMaterialDrag);
          should_continue = true;
        }
      }
      if (should_continue) {
        if (j.status_id <= 1 && this.currentMaterialDrag.Remains != 0) {
          this.currentJobDrag = { ...j };
          this.temp = j;
          this.openModal();
        }
        if (this.$parent.direction == false) {
          this.currentJobDrag = { ...j };
          this.temp = j;
          this.openModal();
        }
      }
      // this.$parent.revaluateSelectedWarehouseJobs();
    },
    onAddDrag() {
      console.log(
        "on add drag",
        this.currentJobDrag.parent_job,
        this.currentMaterialDrag,
        this.currentTruckJobMaterialDrag,
        this.currentJobDrag
      );
      if (this.modal_amount_choosed > 0) {
        var was_transfer = false;

        if (
          this.currentMaterialDrag.job_id != this.currentJobDrag.ej_id &&
          this.$parent.direction &&
          !this.currentJobDrag.connected_project_id
        ) {
          was_transfer = true;
          // update tetris with the warehouse_job that we are transfering the material below
          var mats = {
            IdST: this.currentMaterialDrag.IdST,
            description: this.currentMaterialDrag.Caption,
            amount: this.modal_amount_choosed,
            old_job: this.currentMaterialDrag.job_id,
            new_job: this.currentJobDrag.ej_id,
            project_job_id: this.currentJobDrag.guid,
            project_id: this.$parent.$parent.event_details.ID,
          };

          axios
            .post(
              this.$tetris_server + "/save/warehouse/job/transfer/material",
              mats
            )
            .then(async (r) => {
              let old_material = {
                IdJob: this.currentMaterialDrag.job_id,
                IdStockType: this.currentMaterialDrag.IdST,
                IdStockType2JobGroup: this.currentMaterialDrag.IdST2JG,
                /*IdStockType2JobType: 1,*/
                Quantity: 0 - this.modal_amount_choosed,
              };
              let new_material = {
                IdJob: this.currentJobDrag.ej_id,
                IdStockType: this.currentMaterialDrag.IdST,
                IdStockType2JobGroup: this.currentMaterialDrag.IdST2JG,
                /*IdStockType2JobType: 1,*/
                Quantity: this.modal_amount_choosed,
              };
              var list_of_materials = [];
              list_of_materials.push(old_material);
              list_of_materials.push(new_material);

              await this.$parent.$parent
                .postEJDatas("custom/batchbook/", list_of_materials)
                .then((new_material_request) => {
                  console.log(new_material_request, r);
                  let was_fount_at_destination_job = false;
                  this.$parent.$parent.jobs = [
                    ...this.$parent.$parent.jobs.map((j) => {
                      if (j.ID == this.currentJobDrag.ej_id) {
                        j.materials = [
                          ...j.materials.map((m) => {
                            if (m.IdST == this.currentMaterialDrag.IdST) {
                              was_fount_at_destination_job = true;
                              m.QtyWas = m.QtyWas + this.modal_amount_choosed;
                            }
                            return m;
                          }),
                        ];
                      }

                      if (j.ID == this.currentMaterialDrag.job_id) {
                        j.materials = [
                          ...j.materials.map((m) => {
                            if (m.IdST == this.currentMaterialDrag.IdST)
                              m.QtyWas = m.QtyWas - this.modal_amount_choosed;
                            return m;
                          }),
                        ];
                      }
                      return j;
                    }),
                  ];

                  if (was_fount_at_destination_job == false) {
                    this.$parent.$parent.jobs = [
                      ...this.$parent.$parent.jobs.map((j) => {
                        if (j.ID == this.currentJobDrag.ej_id) {
                          j.materials.push({
                            ...this.currentMaterialDrag,
                            QtyWas: this.modal_amount_choosed,
                            Qty: this.modal_amount_choosed,
                          });
                        }
                        return j;
                      }),
                    ];
                  }

                  this.pushMaterialToJob(r.data);

                  // TODO! Few things are still missing in the refresh of the data and is not needed to do general refresh of the browser.
                  // TODO FUTURE
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                });
            });
        }
        if (!was_transfer) {
          axios
            .post(this.$tetris_server + "/save/truck/job/material", {
              project_id: this.$parent.$parent.currentEvent,
              job: this.currentJobDrag,
              material: {
                ...(this.currentJobDrag.parent_job == null
                  ? this.currentMaterialDrag
                  : this.currentTruckJobMaterialDrag),
                amount_value: this.modal_amount_choosed,
              },
            })
            .then((res) => {
              if (this.currentJobDrag.parent_job == null) {
                this.currentMaterialDrag.Qty =
                  this.currentMaterialDrag.Qty - this.modal_amount_choosed;

                // this.temp.Qty = [...this.temp.Qty,
                // 	// {...this.currentMaterialDrag,
                // 	// 	Qty: this.currentJobDrag.status_id == 0  ? this.modal_amount_choosed : 0,
                // 	// 	loaded_amount: this.currentJobDrag.status_id == 1 ? this.modal_amount_choosed : 0
                // 	// }
                // 	{...res.data[0]}
                // ]

                //this.temp.Qty.push( {...res.data[0]})
              } else if (this.currentJobDrag.parent_job != null) {
                //	this.temp.Qty.push( {...res.data[0]})
                // this.temp.Qty = [...this.temp.Qty,
                // 	{...res.data[0]}
                // ]
              }

              // if is a truck job or a stop
              this.pushMaterialToJob(res.data);
            });
        }
        // this.$parent.revaluateSelectedWarehouseJobs();
      }
    },
    pushMaterialToJob(data) {
      let fount = false;
      this.$parent.project_trucks_jobs.forEach((p) => {
        if (p.guid == this.temp.guid) {
          var material_exists = false;
          p.Qty = [
            ...p.Qty.map((m) => {
              if (m.guid == data[0]["guid"]) {
                material_exists = true;
                m = { ...data[0] };
              }
              return m;
            }),
          ];

          if (!material_exists) p.Qty.push({ ...data[0] });

          fount = true;
        }
      });
      if (!fount) {
        this.$parent.project_trucks_jobs.forEach((job) => {
          if (job.guid == this.temp.parent_job)
            job.stops.forEach((s) => {
              if (s.guid == this.temp.guid) {
                var material_exists = false;
                s.Qty = [
                  ...s.Qty.map((m) => {
                    // console.log(m.guid + " == " + data[0]['guid'])
                    if (m.guid == data[0]["guid"]) {
                      material_exists = true;
                      m = { ...data[0] };
                    }
                    return m;
                  }),
                ];

                if (!material_exists) s.Qty.push({ ...data[0] });
              }
            });
        });
      }
      socket.emit("tell others", {
        type: "add material",
        data: { ...data[0] },
      });

      this.$parent.revaluateSelectedWarehouseJobs();
      // this.$parent.get_project_truck_jobs(true)

      this.currentMaterialDrag = {};
      this.currentTruckJobMaterialDrag = {};
      this.currentJobDrag = {};
      this.modal_amount_choosed = 0;
    },
    addNewJob(job, guid, tj, stop) {
      if (!job.truck_jobs) job.truck_jobs = [];

      this.$fire({
        text:
          guid == null
            ? this.$t("are_you_sure_to_create_truck")
            : this.$t("are_you_sure_to_add_stop"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          // API - Create project jobs
          var a = {
            project_id: this.$parent.$parent.currentEvent,
            ej_id: job.IdJob,
            warehouse_id: job.Stock.ID,
            start_date: job.DayTimeOut,
            end_date: job.DayTimeIn,
            parent_job: guid,
            stop_location_sort: guid ? tj.stops.length : 0,
            stop_data: stop ? stop : "",
            direction: this.$parent.direction ? 0 : 1,
            time: "13:30",
          };
          axios.post(this.$tetris_server + "/create/truck/job", a).then((r) => {
            if (guid == null) {
              if (this.$parent.direction) {
                job.truck_jobs.push({ ...r.data[0], Qty: [] });
                socket.emit("tell others", {
                  type: "new truck",
                  data: { ...r.data[0], Qty: [], job: job, guid: guid },
                });
              } else {
                job.returning_jobs.push({ ...r.data[0], Qty: [] });
                socket.emit("tell others", {
                  type: "new truck",
                  data: { ...r.data[0], Qty: [], job: job, guid: guid },
                });
              }
              // update also the main array that has all the truck jobs
              this.$parent.project_trucks_jobs.push({ ...r.data[0], Qty: [] });

              this.$parent.selected_warehouse_jobs = [
                ...this.$parent.selected_warehouse_jobs.map((o) => {
                  if (o.ID == job.ID) {
                    if (this.$parent.direction)
                      o.truck_jobs = [...job.truck_jobs];
                    else o.returning_jobs = [...job.returning_jobs];
                  }
                  return o;
                }),
              ];

              this.$parent.invalidateSelectedWarehouseJobs();
              this.$parent.revaluateSelectedWarehouseJobs();

              this.$toast(this.$t("truck_created_planning"), "success", 600);
            } else {
              tj.stops.push({ ...r.data[0], Qty: [] });
              socket.emit("tell others", {
                type: "new stop",
                data: { ...r.data[0], Qty: [] },
              });
              this.close_stop_modal();
              this.$toast("A new stop has been created", "success", 600);
            }
          });
        }
      });
    },
    addNewFullJob(job) {
      if (!job.truck_jobs) job.truck_jobs = [];

      let m = [...job.materials.filter((e) => e.Qty > 0)];

      job.truck_jobs.push({
        DayTimeOut: job.DayTimeOut,
        DayTimeIn: job.DayTimeIn,
        Qty: [...m],
        Title: "final",
      });
      this.$parent.selected_warehouse_jobs =
        this.$parent.selected_warehouse_jobs.map((o) => {
          if (o.ID == job.ID) {
            o.truck_jobs = job.truck_jobs;
          }
          return o;
        });
    },
    getMaterialData(m) {
      let mat = {};
      this.$parent.$parent.materials_data.forEach((material) => {
        if (material.IdStockType == m.IdST) mat = material;
      });
      return mat;
    },
    truckJobWeightVolume(t) {
      let d = {
        Weight: 0,
        Height: 0,
        Width: 0,
        Depth: 0,
      };
      t.Qty.forEach((e) => {
        d.Weight =
          parseFloat(d.Weight) +
          parseFloat(this.getMaterialData(e).Weight) *
            (t.status_id == 0 ? e.Qty : e.loaded_amount);
        d.Height =
          parseFloat(d.Height) + parseFloat(this.getMaterialData(e).Height);
        d.Width =
          parseFloat(d.Width) + parseFloat(this.getMaterialData(e).Width);
        d.Depth =
          parseFloat(d.Depth) + parseFloat(this.getMaterialData(e).Depth);
      });
      return d;
    },
    openModal() {
      this.showModal = true;
    },
    disabledOutOfJobDates(j) {
      return (
        j < new Date(this.$parent.$parent.event_details.StartDate) ||
        j > new Date(this.$parent.$parent.event_details.EndDate)
      );
    },
    // disabledArrivalDates(j) { //was like this
    //   if (j.details && j.details.departs_at) {
    //     return (
    //       j < new Date(j.details.departs_at) ||
    //       j > new Date(this.$parent.$parent.event_details.EndDate)
    //     );
    //   }
    // },
    disabledArrivalDates(j) {
      if (j.details && j.details.arrives_at) {
        return (
          j < new Date(j.details.arrives_at) ||
          j > new Date(this.$parent.$parent.event_details.EndDate)
        );
      }
    },
    startDeparture(truck_job) {
      let departed_at = new Date();
      truck_job.details.departed_at = departed_at;
      axios
        .post(this.$tetris_server + "/start/truck/departure", {
          truck_job: truck_job,
        })
        .then(() => {
          this.$parent.project_trucks_jobs = [
            ...this.$parent.project_trucks_jobs.map((tj) => {
              if (tj.guid == truck_job.guid) {
                tj.details.departed_at = truck_job.details.departed_at;
              }
              return tj;
            }),
          ];
          // truck_job.was_departed = 1;
          this.$parent.revaluateSelectedWarehouseJobs();
          socket.emit("tell others", {
            type: "job status changed",
            data: truck_job,
          });
          this.$toast(this.$t("truck_departed"), "success", 600);
        });
    },
    save_truck_job_details(d, change_date) {
      if (
        this.$moment(d.departs_at_original).isSameOrAfter(
          this.$moment(d.arrives_at_original)
        )
      ) {
        // console.log("departs_at is greater than arrives_at");
        d.departs_at_original = this.$moment(d.arrives_at_original)
          .subtract(6, "hours")
          .format("YYYY-MM-DD HH:mm:ss"); //.utc() //
        d.departs_at = d.departs_at_original;

        // console.log(d.departs_at);
        // d.departs_at_original = d.departs_at; //.utc() //
        this.$toast("The arrival time can not be before departure!", "error");
        // d.departs_at = d.departs_at_original;
      } else {
        // was commented out but was this necessary?

        if (d.arrives_at != d.arrives_at_original) {
          d.arrives_at = d.arrives_at_original;
        }
        if (d.departs_at != d.departs_at_original) {
          d.departs_at = d.departs_at_original;
        }

        setTimeout(() => {
          axios
            .post(this.$tetris_server + "/save/truck/job/details", {
              details: d,
              selected_date: change_date,
            })
            .then((res) => {
              // this.stop_modal_data = {...d}
              // this.$parent.revaluateSelectedWarehouseJobs()

              if (res.data.length == 1) {
                d = {
                  ...res.data[0],
                  arrives_at_original: res.data[0].arrives_at,
                  departs_at_original: res.data[0].departs_at,
                };
              }

              this.$parent.project_trucks_jobs = [
                ...this.$parent.project_trucks_jobs.map((tj) => {
                  // console.log(tj.guid, d.project_job_id)
                  if (tj.guid == d.project_job_id) tj.details = { ...d };
                  else {
                    tj.stops = tj.stops.map((s) => {
                      if (s.guid == d.project_job_id)
                        s.details = { ...s.details, ...d };
                      return s;
                    });
                  }
                  return tj;
                }),
              ];
              this.$parent.revaluateSelectedWarehouseJobs();
              socket.emit("tell others", {
                type: "edit truck header",
                data: d,
              });
              this.$toast("Truck info saved", "info", 600);
              this.close_stop_modal();
            });
        }, 900);
      }
    },
    async complete_truck_job_planning(tj, wj) {
      console.log("tj", tj);
      let htmltext = "";
      if (tj.status_id == 0)
        // is planning
        htmltext =
          "<b>" +
          this.getTrucksName(
            tj.details.truck_id,
            this.$parent.warehouses_parameters
          ) +
          "</b> " +
          this.$t("sure_you_want_complete_planning_truck");
      else if (
        tj.status_id == 1 &&
        this.$parent.direction &&
        !tj.connected_project_id
      )
        // delivery loading
        htmltext =
          "<b>" +
          this.getTrucksName(
            tj.details.truck_id,
            this.$parent.warehouses_parameters
          ) +
          "</b> " +
          this.$t("sure_you_want_complete_loading_truck");
      else if (
        tj.status_id == 1 &&
        this.$parent.direction &&
        tj.connected_project_id
      )
        // delivery loading
        htmltext =
          "<b>" +
          this.getTrucksName(
            tj.details.truck_id,
            this.$parent.warehouses_parameters
          ) +
          "</b> " +
          this.$t("sure_you_want_complete_loading_truck_return");
      else if (tj.status_id == 1 && !this.$parent.direction)
        // delivery return
        htmltext =
          "<b>" +
          this.getTrucksName(
            tj.details.truck_id,
            this.$parent.warehouses_parameters
          ) +
          "</b> " +
          this.$t("sure_you_want_complete_loading_truck_return");
      this.$fire({
        html: htmltext,
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then(async (response) => {
        if (response.value) {
          // if the truck / dates / delivery location has been set then save (only for status 1 and 2)
          if (tj.details.id != null && tj.details.truck_id != null) {
            let loaded_at = new Date();
            let end_date = wj.pickup_date;
            axios
              .post(this.$tetris_server + "/complete/truck/job", {
                truck_job: tj,
                // departs_at: departs_at,
                loaded_at: loaded_at,
                end_date: end_date,
                direction: this.$parent.direction ? 0 : 1,
              })
              .then(async () => {
                tj.status_id = tj.status_id + 1;
                if (tj.status_id == 1) {
                  this.$toast(this.$t("job_planning_completed"));
                }

                console.log(tj, "tj here ");
                if (tj.status_id == 2) {
                  // TODO here it should:
                  // 1. create the jobs in easyjob if is a delivery and status_id == 2 -> was loaded to truck

                  if (this.$parent.direction && !tj.connected_project_id) {
                    //set the checkout date the time you are clicking on checkout
                    let start_date = new Date();
                    let end_date = wj.pickup_date;
                    // tj.details.departs_at = start_date;
                    tj.details.loaded_at = loaded_at;
                    // tj.details.arrives_at = end_date;

                    // 0. Should first create a project_job_tx for the checkout in Tetris and get its guid for the materials
                    await axios
                      .post(this.$tetris_server + "/save/truck/job/checkout", {
                        ...tj,
                        created_by: JSON.parse(localStorage.user).IdUser,
                        end_date: end_date,
                        start_date: start_date,
                      })
                      .then(async (tetris_checkout_response) => {
                        // THIS ACTS AS A CONFIG to create multiple jobs or just one
                        let multiple_jobs = false;

                        let checkout_guid = tetris_checkout_response.data.guid;

                        //commented but not sure if it is needed
                        // tj.details.arrives_at =
                        //   tetris_checkout_response.data.date_checkout;
                        // tj.details.departs_at =
                        //   tetris_checkout_response.data.date_checkout;
                        //til here

                        this.save_truck_job_details(tj.details);
                        // let user = JSON.parse(localStorage.tetris_user)

                        // TODO ERRORS HERE MUST BE CONFIRMED THIS WORKS!!!
                        let starts = tj.details.loaded_at; //this.$moment(tj.details.departs_at).add(user.timezone_offset, "hours") //.utc() //.add(0, "hours") //
                        let ends = wj.pickup_date; //this.$moment(wj.pickup_date).add(user.timezone_offset, "hours") //.utc()//.add(0, "hours") //

                        if (multiple_jobs) {
                          // will create multiple jobs and will add a material for each of them
                          for (var i = 0; i < tj.Qty.length; i++) {
                            let job = {
                              Caption:
                                this.$parent.selected_warehouse.Caption +
                                " - t:" +
                                this.getTrucksName(
                                  tj.details.truck_id,
                                  this.$parent.warehouses_parameters
                                ),
                              Address_Delivery: null,
                              Contact_Delivery: null,
                              DayTimeOut: starts, // tj.details.departs_at,
                              DayTimeIn: ends, //tj.end_date,
                              IdJob: null,
                              JobState: {
                                ID: 1, // Status of the job should be confirmed in order to check it out
                                IdJobState: 1, // same here
                              },
                              Project: {
                                ID: this.$parent.$parent.currentEvent,
                                IdProject: this.$parent.$parent.currentEvent,
                              },
                              Stock: {
                                ID: tj.warehouse_id,
                                IdStock: tj.warehouse_id,
                              },
                            };
                            // [1] Save the job to easyjob
                            await this.$parent.$parent
                              .postEJDatas("jobs/save/", job)
                              .then((response) => {
                                if (response.data.Success == true) {
                                  let new_job = response.data.ID;
                                  // Add here the materials for each job
                                  // Logic here is to add a job for each material
                                  let new_material = {
                                    IdJob: new_job,
                                    IdStockType: tj.Qty[i].IdST,
                                    Quantity: tj.Qty[i].loaded_amount,
                                  };
                                  // [2] Save each material in a single job
                                  this.$parent.$parent
                                    .postEJDatas("items/book", new_material)
                                    .then(() => {
                                      this.$parent.$parent.general_toaster =
                                        "[EASY JOB MATERIAL RESPONSE] ------------" +
                                        "--------- " +
                                        response.data.Message;
                                      // after Booking the single material of the job
                                      // 2. checkout the job material
                                      this.$parent.$parent
                                        .postEJDatas(
                                          "custom/jobcheckout/" + new_job,
                                          {}
                                        )
                                        .then((checkout_response) => {
                                          // debugger; // eslint-disable-line no-debugger

                                          this.$parent.$parent.general_toaster =
                                            "[EASY JOB MATERIAL RESPONSE] ---------" +
                                            "------------ " +
                                            checkout_response.data.Message;
                                          // 3. TODO: Store the checkout in tetris database
                                          // with possibly also the checkout id so can easily be used for the checkin back
                                          // Use the guid of the stored checkout / tx job for creating the materials
                                          axios
                                            .post(
                                              this.$tetris_server +
                                                "/checkout/material",
                                              {
                                                tx_id: checkout_guid,
                                                project_job_id: tj.guid,
                                                ej_id: new_job,
                                                amount: new_material.Quantity,
                                                material:
                                                  new_material.IdStockType,
                                                start_date: start_date,
                                                end_date: end_date,
                                                warehouse_id: tj.warehouse_id,
                                              }
                                            )
                                            .then(() => {
                                              this.$parent.revaluateSelectedWarehouseJobs();
                                            });
                                        });
                                    });
                                } else {
                                  // ERROR: job was not created
                                  this.$parent.$parent.general_toaster =
                                    "[EASY JOB RESPONSE] ---------" +
                                    "------------ " +
                                    response.data.Message +
                                    ": " +
                                    JSON.stringify(response.data.ErrorMessages);
                                }
                              });
                          } // end of cycle the materials
                        } else {
                          // creates a single job with all the materials in
                          let job = {
                            Caption:
                              this.$parent.$parent.event_details.Caption +
                              " - " +
                              this.$parent.selected_warehouse.Caption +
                              " - t:" +
                              this.getTrucksName(
                                tj.details.truck_id,
                                this.$parent.warehouses_parameters
                              ),
                            Address_Delivery: null,
                            Contact_Delivery: null,
                            DayTimeOut: starts, // tj.details.departs_at,
                            DayTimeIn: ends, //tj.end_date,
                            IdJob: null,
                            JobState: {
                              ID: 1, // Status of the job should be confirmed in order to check it out
                              IdJobState: 1, // same here
                            },
                            Project: {
                              ID: this.$parent.$parent.currentEvent,
                              IdProject: this.$parent.$parent.currentEvent,
                            },
                            Stock: {
                              ID: tj.warehouse_id,
                              IdStock: tj.warehouse_id,
                            },
                          };
                          // [1] Save the job to easyjob
                          await this.$parent.$parent
                            .postEJDatas("jobs/save/", job)
                            .then((response) => {
                              if (response.data.Success == true) {
                                let new_job = response.data.ID;
                                let materials_list = [];

                                for (var i = 0; i < tj.Qty.length; i++) {
                                  materials_list.push({
                                    IdJob: new_job,
                                    IdStockType: tj.Qty[i].IdST,
                                    Quantity: tj.Qty[i].loaded_amount,
                                  });
                                }

                                this.$parent.$parent
                                  .postEJDatas(
                                    "custom/batchbook/",
                                    materials_list
                                  )
                                  .then(async (new_material_request) => {
                                    console.log(new_material_request, "aaa");
                                    axios
                                      .post(
                                        this.$parent.$parent.$tetris_server +
                                          "/checkout/materials",
                                        {
                                          tx_id: checkout_guid,
                                          project_job_id: tj.guid,
                                          ej_id: new_job,
                                          materials: materials_list,
                                          start_date: start_date,
                                          end_date: end_date,
                                          warehouse_id: tj.warehouse_id,
                                        }
                                      )
                                      .then(async () => {
                                        this.$parent.$parent
                                          .postEJDatas(
                                            "custom/jobcheckout/" + new_job,
                                            {}
                                          )
                                          .then(async (checkout_response) => {
                                            // debugger; // eslint-disable-line no-debugger
                                            let message =
                                              checkout_response.data.Message ==
                                              null
                                                ? "Success"
                                                : checkout_response.data
                                                    .Message;
                                            this.$parent.$parent.general_toaster =
                                              "[EASY JOB MATERIAL RESPONSE] ---------" +
                                              "------------ " +
                                              message;
                                            // 3. TODO: Store the checkout in tetris database
                                            // with possibly also the checkout id so can easily be used for the checkin back
                                            // Use the guid of the stored checkout / tx job for creating the materials
                                          });
                                        this.$parent.revaluateSelectedWarehouseJobs();
                                      });
                                  });
                              }
                            });
                        }
                      });
                  } else {
                    //arrival date on Return ($parent.$parent.direction == 1)
                    tj.details.arrives_at = new Date();
                    //revaluate check-in data too
                    this.$parent.revaluateSelectedWarehouseJobs();
                  }
                  this.$toast(this.$t("job_loading_completed"));
                }
                // tell the others about the loading of the job
                socket.emit("tell others", {
                  type: "job status changed",
                  data: tj,
                });
              });
          } else {
            alert(this.$t("specify_job_details"));
          }
        }
      });
    },
    delete_truck_job_material(tj, m) {
      this.$fire({
        text: this.$t("sure_remove_material"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          axios
            .post(this.$tetris_server + "/delete/truck/job/material", {
              material: m,
              truck_job: tj,
            })
            .then(() => {
              tj.Qty = tj.Qty.filter((a) => a.guid != m.guid);
              this.$parent.revaluateSelectedWarehouseJobs();
              this.$toast(this.$t("material_removed_success"), "danger");
              socket.emit("tell others", {
                type: "delete truck material",
                data: [tj, m],
              });
            })
            .catch(() => {});
        }
      });
    },
    is_job_completed(job) {
      var a = true;
      if (this.$parent.direction) {
        // if is delivery
        job.materials.forEach((m) => {
          if (m.Remains > 0) a = false;
        });
        // job.truck_jobs.forEach(tj => {
        // 	if(tj.status_id > 2)
        // 		return false
        // 		// a = false
        // })
        return a;
      } else {
        // returning jobs
        job.materials.forEach((m) => {
          if (m.LoadedAmount != m.ReturnedAmount) {
            a = false;
          }
        });
        // job.returning_jobs.forEach(tj => {
        // 	if(tj.status_id >= 2){
        // 		return a = true
        // 	}
        // })
        return a;
      }
    },
    destination_name(id) {
      var v = this.$parent.warehouses_parameters.filter((p) => p.id == id);
      if (v.length > 0) {
        return v[0].value;
      }
    },
    delete_form_all_truck_jobs(tj) {
      this.$parent.project_trucks_jobs =
        this.$parent.project_trucks_jobs.filter((t) => t.guid != tj.guid);
    },
    delete_truck_stop(tj, truck_job, idx) {
      this.$fire({
        // title: 'Are you sure?',
        text: this.$t("sure_delete_truck_stop"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          axios.post(this.$tetris_server + "/delete/truck/job", tj).then(() => {
            truck_job.stops = truck_job.stops.filter((t) => t.guid != tj.guid);
            socket.emit("tell others", {
              type: "delete stop job",
              data: { ...tj, job: truck_job, index: idx },
            });
            this.delete_form_all_truck_jobs(tj);
            this.$parent.invalidateSelectedWarehouseJobs();
            this.close_stop_modal();
            console.debug(idx);
          });
        }
      });
    },
    delete_truck_job(tj, job, index) {
      // if(confirm("Are you sure you want to delete the truck job #"+(index+1)+"?"))

      this.$fire({
        // title: 'Are you sure?',
        text: this.$t("sure_delete_truck_job"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          axios.post(this.$tetris_server + "/delete/truck/job", tj).then(() => {
            console.log("delete tj", tj);
            let l = [
              ...this.$parent.selected_warehouse_jobs.map((swj) => {
                //if(swj.ID == job.ID){
                if (this.$parent.direction)
                  swj.truck_jobs = swj.truck_jobs.filter(
                    (t) => t.guid != tj.guid
                  );
                else
                  swj.returning_jobs = swj.returning_jobs.filter(
                    (t) => t.guid != tj.guid
                  );
                // swj.truck_jobs = u
                // }
                return swj;
              }),
            ];
            this.$parent.$parent.jobs = [
              ...this.$parent.$parent.jobs.map((atj) => {
                if (atj.truck_jobs) {
                  if (this.$parent.direction)
                    atj.truck_jobs = atj.truck_jobs.filter(
                      (tr) => tr.guid != tj.guid
                    );
                  else
                    atj.returning_jobs = atj.returning_jobs.filter(
                      (tr) => tr.guid != tj.guid
                    );
                }
                return atj;
              }),
            ];
            console.log("this", this.$parent.$parent.jobs);
            socket.emit("tell others", {
              type: "delete truck job",
              data: { ...tj, job: job, index: index },
            });
            this.delete_form_all_truck_jobs(tj);
            this.$parent.selected_warehouse_jobs = l;
            this.$parent.invalidateSelectedWarehouseJobs();
            this.$toast(this.$t("truck_job_deleted_success"), "danger");
            // TO DO if truck job (tj) have materials -> calculate material amount
          });
        }
      });
    },
    cancelcallback() {},
    collapse_all_jobs(job) {
      let have_collapsed = false;
      if (this.$parent.direction) {
        job.truck_jobs.map((tj) => {
          if (tj.collapsed == true) have_collapsed = true;
        });
        this.$parent.selected_warehouse_jobs = [
          ...this.$parent.selected_warehouse_jobs.map((j) => {
            if (j.IdJob == job.IdJob) {
              j.truck_jobs.map((tj) => {
                // console.log('here?')
                tj.collapsed = !have_collapsed;
                return tj;
              });
            }
            return j;
          }),
        ];
      } else {
        job.returning_jobs.map((tj) => {
          if (tj.collapsed == true) have_collapsed = true;
        });
        // console.log('only in return...', this.$parent.returning_jobs.filter(wj => wj.IdJob == job.IdJob ), job)
        this.$parent.selected_warehouse_jobs = [
          ...this.$parent.selected_warehouse_jobs.map((j) => {
            if (j.IdJob == job.IdJob) {
              j.returning_jobs.map((tj) => {
                // console.log('here?')
                tj.collapsed = !have_collapsed;
                return tj;
              });
            }
            return j;
          }),
        ];
      }
      return have_collapsed;
    },
    are_all_checked(job) {
      let have_empty = [];
      if (this.$parent.direction) {
        job.truck_jobs.map((tj) => {
          if (tj.collapsed == true) have_empty.push(tj);
        });
      } else {
        job.returning_jobs.map((tj) => {
          if (tj.collapsed == true) have_empty.push(tj);
        });
      }
      return have_empty.length == 0 ? true : false;
    },
    save_single_truck_job_material(t, tj, index, step, job) {
      //if step = 1 (still on planning, check if it's zero first)

      let totalLoaded = job.materials.find(
        (m) => m.IdST == t.IdST
      ).LoadedAmount;
      let totalReturned = job.materials.find(
        (m) => m.IdST == t.IdST
      ).ReturnedAmount;

      let onsite = parseInt(totalLoaded) - parseInt(totalReturned);
      // console.log(job.materials.find(m => m.IdST == t.IdST).QtyWas, "jemi ktu");
      // console.log(onsite, "jemi ktu 3");

      if (step == 1) {
        if (t.Qty == 0) {
          t.Qty = 1;
        }
        this.$parent.revaluateSelectedWarehouseJobs();

        axios
          .post(this.$tetris_server + "/save/single/truck/job/material", {
            guid: t.guid,
            amount: t.Qty,
            loaded_amount: t.loaded_amount ? t.loaded_amount : 0,
            truck_job: tj,
          })
          .then((res) => {
            tj.Qty[index] = res.data[0];
            this.$parent.revaluateSelectedWarehouseJobs();
            // console.log(tj.Qty[index], "jemi ktu 2");
            // console.log(
            //   this.$parent.selected_warehouse_jobs[index],
            //   "jemi ktu req am"
            // );

            // let my_remains = 0;
            let allmaterials =
              this.$parent.selected_warehouse_jobs[index].materials;
            //map a to get the material with the same guid as t.guid
            let my_mat = allmaterials.find((a) => a.IdST == t.IdST);

            // console.log(my_mat, "jemi ktu 4");
            if (my_mat.Remains > 0) {
              if (my_mat.Remains - t.Qty < 0) {
                tj.Qty[index].loadingMore = 1;
              } else {
                tj.Qty[index].loadingMore = 0;
              }
              tj.Qty[index].missing = my_mat.Remains - t.Qty;
            } else if (my_mat.RemainsToReturn == 0) {
              if (onsite - t.Qty < 0) {
                tj.Qty[index].loadingMore = 1;
              } else {
                tj.Qty[index].loadingMore = 0;
              }
              tj.Qty[index].missing = onsite - t.Qty;
            }

            socket.emit("tell others", {
              type: "edit material",
              data: { ...res.data[0], tj, index },
            });
          });
      } else
        axios
          .post(this.$tetris_server + "/save/single/truck/job/material", {
            guid: t.guid,
            amount: t.Qty,
            loaded_amount: t.loaded_amount ? t.loaded_amount : 0,
            truck_job: tj,
          })
          .then((res) => {
            tj.Qty[index] = res.data[0];
            this.$parent.revaluateSelectedWarehouseJobs();
            socket.emit("tell others", {
              type: "edit material",
              data: { ...res.data[0], tj, index },
            });
          });
    },
    jobs(job) {
      let a = [
        ...(this.$parent.direction == false
          ? job.returning_jobs
          : job.truck_jobs),
      ];
      return a;
    },
    selected_truck_jobs(job) {
      let a = [
        ...(this.$parent.direction == false
          ? job.returning_jobs
          : job.truck_jobs),
      ];
      return a; //.filter(e => e.collapsed)
    },
    open_job_dates_modal(job) {
      this.show_job_dates_modal = true;
      this.selected_job = job;
    },
    truck_prefix_name(t) {
      if (t.form_value) {
        if (t.form_value instanceof Array) return t.form_value[0].value;
        else return JSON.parse(t.form_value)[0].value;
      } else {
        return "";
      }
    },
    open_chekcin_modal(tj) {
      this.$parent.selected_truck_job = tj;
      // if tx_guid (is checked in)
      if (this.$parent.selected_truck_job.tx_guid) {
        // Get TX
        axios
          .post(this.$tetris_server + "/get/job/tx", {
            tx_guid: this.$parent.selected_truck_job.tx_guid,
          })
          .then((r) => {
            this.$parent.selected_truck_job.tx_data = r.data[0];
            this.$parent.selected_truck_job.tx_guid = r.data[0].guid;
          });
      }
      this.show_checkin_modal = !this.show_checkin_modal;
    },
    open_tj_comments_modal(tj) {
      // Assign selected job
      this.$parent.selected_truck_job = tj;
      // Show modal
      this.show_tj_comments_modal = true;
    },
    disableTime(date, s) {
      console.log("date", date, s);
      // console.log('', this.truck_job.details.departs_at)
      // return date < new Date(this.truck_job.details.departs_at);
    },
    start_show_stop_modal(a, stop, truck_job, job, iidx) {
      this.show_stop_modal = true;
      this.is_created = a;
      this.stop_modal_data = stop;
      this.stop_modal_truck_job = truck_job;
      this.stop_modal_job = job;
      this.selected_gate_index = iidx;
    },
    close_stop_modal() {
      this.show_stop_modal = false;
      this.is_created = false;
      // this.stop_modal_data = {}
      this.stop_modal_truck_job = {};
      this.stop_modal_job = {};
      this.selected_gate_index = "";
    },
  },
};
